<template>
  <div
    class="WasteItem"
    :class="{IsDone: wasteItem.isDone}">
    <!-- Checkbox -->
    <Checkbox
      :isSelected="wasteItem.isDone"
      :name="groupedType.id + 'is-done'"
      @checkbox-click="onDoneClick" />

    <!-- Title -->
    <span class="Title">
      {{ groupedType.categoryIndex }}.{{ groupedType.index }} {{ groupedType.getGroupedTypeTitle(
        { withDetails: true }
      ) }}
    </span>

    <!-- Details -->
    <div class="DetailsWrap">
      <div class="SampleNumbers">
        {{ getSampleNumbers() }}
      </div>
      <div
        v-if="results.length === 1"
        class="Results Single">
        <span :class="results[0].className">{{ results[0].resultText }}</span>
      </div>
      <div
        v-if="results.length === 2"
        class="Results Double">
        <span :class="results[0].className">{{ results[0].resultText }}</span>
        <span :class="results[1].className">{{ results[1].resultText }}</span>
      </div>
      <div class="Amount">
        {{ groupedType.getAmount() || '-' }}
      </div>
      <div class="Amount">
        <InputField
          :name="groupedType.id + 'material-amount'"
          :value="wasteItem.materialAmount"
          :placeholder="''"
          :unit="mixWB('TONS')"
          @blur="onAmountFieldBlur" />
      </div>
      <div class="Amount">
        <InputField
          :name="groupedType.id + 'coating-amount'"
          :value="wasteItem.coatingAmount"
          :placeholder="''"
          :unit="mixWB('TONS')"
          v-if="results.length === 2"
          @blur="onAmountFieldBlur" />
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import { mapGetters } from 'vuex'
import WasteItem from '@/globals/javascript/models/WasteItem'
import Checkbox from '../FormElements/Checkbox.vue'

export default {
  name: 'WasteItem',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      results: [],
      wasteItem: {},
    }
  },
  computed: {
    ...mapGetters([
      'screeningWasteItems',
    ]),
  },
  methods: {
    setDataOnLoad() {
      const savedWasteItem = this.screeningWasteItems.find(
        (x) => x.groupedTypeID === this.groupedType.id,
      )

      if (savedWasteItem) {
        this.wasteItem = new WasteItem({
          ...savedWasteItem,
        })
        return
      }

      this.wasteItem = new WasteItem({
        groupedTypeID: this.groupedType.id,
      })
    },
    getSampleNumbers() {
      const sampleNumbers = []
      this.groupedType.getSamples().forEach((sample) => {
        if (sample.sampleNumber || sample.equivalentSampleNumber) {
          sampleNumbers.push(`P${ sample.sampleNumber || sample.equivalentSampleNumber }`)
        }
        else if (sample.assessNumber) {
          sampleNumbers.push(`V${ sample.assessNumber }`)
        }
      })
      return sampleNumbers.join(' + ')
    },
    getResultsOnLoad() {
      const {
        results,
      } = this.groupedType.groupedTypeResult.getAllResults({
        groupedType: this.groupedType,
        previousGroupedTypes: this.groupedType.previousGroupedTypes,
        nextGroupedTypes: this.groupedType.nextGroupedTypes,
        groupedTypeTitle: this.groupedType.getGroupedTypeTitle(),
        lastTypeTitleWithCoating: this.groupedType.getGroupedTypeTitle({
          withDetails: true,
          lastTypeOnly: true,
        }),
      })

      this.results = results
    },
    onDoneClick() {
      this.wasteItem.isDone = !this.wasteItem.isDone
      this.onSaveItem()
    },
    onAmountFieldBlur({ name, value }) {
      if (name.includes('material-amount')) {
        this.wasteItem.materialAmount = value
      }
      if (name.includes('coating-amount')) {
        this.wasteItem.coatingAmount = value
      }
      this.onSaveItem()
    },
    onSaveItem() {
      this.$store.dispatch('setWasteItem', this.wasteItem)
    },
  },
  components: { InputField, Checkbox },
  created() {
    this.getResultsOnLoad()
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .WasteItem
    position relative
    display flex
    justify-content space-between
    align-items center
    padding-left 5px
    &:nth-child(odd)
      background-color $color_grey_lightest
    >>> .Checkbox
      margin-bottom 0
    &.IsDone
      &:after
        content ''
        position absolute
        top 0
        left 0
        box(100%)
        background-color rgba($color_primary, 50%)
        pointer-events none

  .Title
    flex-grow 2
    padding-left 5px

  .DetailsWrap
    display flex
    flex-shrink 0
    .SampleNumbers
      flex-center-children()
      padding-right 10px
      white-space nowrap
      min-width 70px
      text-align center
    .Results
      display flex
      min-height 30px
      span
        text-align center
        flex-center-children()
      &.Single
      span
        width 180px
      &.Double
        span
          width 90px
      .Clean
        background-color $color_report_green
      .Contaminated
        background-color $color_report_yellow
      .Hazardous
        background-color $color_report_red
    .Amount
      width 90px
      flex-center-children()
      >>> .InputField
        margin-bottom 0
</style>
