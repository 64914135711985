<template>
  <div class="WasteTypeList">
    <template v-for="categoryItem in categoriesWithGroupedTypes">
      <div
        class="CategoryWrap"
        v-if="categoryItem.groupedTypes.length"
        :key="categoryItem.id">
        <!-- Category header -->
        <WasteCategoryHeader :categoryItem="categoryItem" />

        <!-- Grouped types -->
        <div class="GroupedTypesList">
          <WasteItem
            v-for="groupedType in categoryItem.groupedTypes"
            :key="groupedType.id"
            :groupedType="groupedType" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WasteCategoryHeader from '@/components/ResultItems/WasteCategoryHeader.vue'
import WasteItem from '@/components/ResultItems/WasteItem.vue'

export default {
  name: 'WasteTypeList',
  computed: {
    ...mapGetters([
      'categoriesWithGroupedTypes',
    ]),
  },
  components: {
    WasteCategoryHeader,
    WasteItem,
  },
}
</script>

<style lang="stylus" scoped>
  .WasteTypeList
    display block

  .CategoryWrap
    padding 0px 20px
    max-width 1200px
    margin 0 auto 20px
</style>
