<template>
  <div class="WasteCategoryHeader">
    <!-- Title -->
    <span class="Title">{{ categoryItem.index }}. {{ mixWB(categoryItem.category.wordbook) }}</span>

    <!-- Details -->
    <div class="DetailsWrap">
      <div class="SampleNumbers">{{ mixWB('SAMPLES_2') }}</div>
      <div class="Results">
        <span>{{ mixWB('MATERIAL') }}</span>
        <span>{{ mixWB('COATING_2') }}</span>
      </div>
      <div class="Amount">{{ mixWB('AMOUNT_FROM_APP') }}</div>
      <div class="Amount">{{ mixWB('AMOUNT_MATERIAL') }}</div>
      <div class="Amount">{{ mixWB('AMOUNT_COATING') }}</div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WasteCategoryHeader',
  props: {
    categoryItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'categories',
    ]),
  },
}
</script>

<style lang="stylus" scoped>
  .WasteCategoryHeader
    display flex
    justify-content space-between
    align-items flex-end

  .Title
    text-transform uppercase
    font-size 1rem
    padding-bottom 2px
    padding 5px 0px 5px 0px

  .DetailsWrap
    display flex
    .SampleNumbers
      flex-center-children()
      padding-right 10px
      min-width 70px
      text-align center
    .Results
      display flex
      min-height 30px
      span
        text-align center
        flex-center-children()
        width 90px
    .Amount
      width 90px
      flex-center-children()
      text-align center
      padding 5px
</style>
