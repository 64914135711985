<template>
  <div class="WasteDistribution">
    <Header />

    <div class="ContentWrap">
      <!-- List of types -->
      <WasteTypeList />
    </div>
  </div>
</template>

<script>
import Header from '@/components/ResultItems/Header.vue'
import WasteTypeList from '@/components/ResultItems/WasteTypeList.vue'

export default {
  name: 'WasteDistribution',
  components: {
    Header,
    WasteTypeList,
  },

}
</script>

<style lang="stylus" scoped>
  .WasteDistribution
    display block
</style>
